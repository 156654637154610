$blue: #0040AA;
$dark-blue: #022C72;
$light-blue: #0080F5;
$secondary: $light-blue;
$secondary-light: #26DCF8;
$black: #000000;
$grey: #525860;
$grey-light: #A6A6A6;
$grey-lighter: #F1F1F1;
$grey-lightest: #F8F8F8;
$green: #4CC700;
$red: #ED0202;
$white: #FFFFFF;
$primary: $blue;
$info: $secondary-light;
$link: $secondary;
$title-color: $primary;
$subtitle-color: $primary;
$scrollbar: #B6B6B6;
$tag-color: $blue;

$gap: 32px;
$mobile: 768px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
